import * as React from "react"
import { graphql } from 'gatsby';

import DataProvider from 'providers/data';

import Layout from 'templates/Layout';
import Content from 'templates/Content';

import { default as Card} from 'components/GridCard';

const DistrictListing = (props) => {
	const { edges } = props.data.allContentPage;
	const { edges: districtEdges } = props.data.allDistrictsDistrict;
	const nodes = edges.map(({ node }) => node);
	let districts = districtEdges.map(({ node }) => node);
	let page = nodes[0];
	if ((props.providerResponse.data.length > 0 || !!districts !== false) && props.providerStatusText === 'LOADED') {
		districts = props.providerResponse.data;
	}

	return (
		<Layout showContactForm={page?.showContactForm} showNewsletterSignUp={page?.showNewsletterSignUp} hoverboards={page?.hoverboards}>
			<Content gridSize={12} {...page} />
			{!!districts?.length && districts.map((card, index) => (
				<Card {...card} columnSize={6} key={`district-${index}`} order={index+1} />
			))}
		</Layout>
	)
}

// export default ContentPage
const getData = (props) => <DataProvider liveRefresh={props.params.liveRefresh} type="DistrictsDistrict" apiParams={{_sort: 'sort'}}><DistrictListing {...props} /></DataProvider>
export default getData;

export const query = graphql`
{
	allContentPage(
	  filter: {uri: {eq: "/our-districts/"}, deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}
	) {
	  edges {
		node {
		  pageTitle
		  meta
		  content {
			main
		  }
		  showContactForm
		  showNewsletterSignUp
		  headlineBody
		  headlineTitle
		  headlineImage
		  headlineType
		  hoverboards
		}
	  }
	}
	allDistrictsDistrict(sort: {fields: sort}) {
	  edges {
		node {
		  title
		  image
		  description
		}
	  }
	}
  }
`